import React, {Component} from "react";
import "./Profiles.scss";
import {Card, Row} from "react-bootstrap";
import Strings from "../../config/Strings";
import {BackButton} from "../../global/tiger-button/BackButton";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import {withToast} from "../../util/ToastService";
import {CreateProfile, GetAccountWithProfiles} from "./ProfileService";
import SingleProfile from "./single-profile/SingleProfile";
import GlobalConstants from "../../config/GlobalConstants";
import {BsPlusLg} from "react-icons/all";
import ErrorCodes from "../../config/ErrorCodes";

class Profiles extends Component {

    constructor(props) {
        super(props);

        this.newProfileInput = React.createRef();

        this.state = {
            loading: true,
            profiles: [],
            maxProfiles: 5, //Default
            wildcardCode: ''
        };

        //Window title
        document.title = "Meine Profile :: tiger.media";
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.accountId !== this.props.accountId) {
            await this.loadProfiles();
        }
        window.scrollTo(0, 0);
    }

    async componentDidMount() {
        await this.loadProfiles();
    }

    async loadProfiles() {
        if (this.props.accountId != null) {
            let response = await GetAccountWithProfiles(this.props.accountId);
            if (!response.error) {
                const profileRes = response.result;

                if (profileRes._embedded?.profiles) {
                    this.setState(prevState => ({
                        ...prevState,
                        profiles: profileRes._embedded.profiles,
                        maxProfiles: profileRes.maxProfiles
                    }));
                }
                this.setState({loading: false});
            } else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_PROFILES + " - " + response.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState({
                    loading: false,
                    profiles: []
                });
            }
        }
    }

    async createNewProfile() {
        const newProfile = {name: "Neues Profil", defaultProfile: false};
        let res = await CreateProfile(newProfile);
        if (!res.error) {
            this.props.history.push({
                pathname: "/" + GlobalConstants.APP_PATH + "profile-details",
                state: res.result._links.self.href
            });
        }
    }

    closeView = () => {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH,
            state: this.state.profileLink
        })
    }

    render() {
        return (
            <div className="content-box">
                <BackButton onClick={() => this.closeView()}/>

                <div className="content-header" style={{color: '#1eaf8c'}}>{Strings.TITLE_PROFILES}</div>
                {!this.state.loading &&
                    <>
                        <h4 className="profiles-amount">
                            {Strings.PROFILES_AMOUNT.replace("{0}", this.state.profiles.length).replace("{1}", this.state.maxProfiles)}
                        </h4>
                        <br />
                        <br />
                        <br />
                    </>
                }
                <div className="mb-4">
                    {!this.state.loading && this.state.profiles.length !== 0 &&
                    <Row className="profile-items-container mx-0">
                        {this.state.profiles.map(p => {
                            return (
                                <SingleProfile key={p.id}
                                               profile={p}
                                               history={this.props.history}
                                />
                            )
                        })}
                        {(this.state.profiles.length < this.state.maxProfiles) &&
                        <Card className="new-profile mx-2" onClick={() => this.createNewProfile()}>
                            <div style={{height: "23.6rem"}}>
                                <div style={{height: "100%"}}>
                                    <BsPlusLg className="add-profile-image" style={{color: '#1eaf8c', fontSize: "8.0rem"}}/>
                                </div>
                            </div>

                            <Card.Body>
                                <Card.Title>{Strings.PROFILES_NEW}</Card.Title>
                            </Card.Body>
                        </Card>
                        }
                    </Row>
                    }

                    {this.state.loading && <TigerSpinner/>}
                </div>
            </div>
        )
    }
}

export default withToast(Profiles);