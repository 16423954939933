import React, {useEffect, useState} from "react";
import './../Purchase.scss';
import {useHistory} from "react-router";
import PurchaseStatus from "../PurchaseStatus";
import Strings from "../../../../config/Strings";
import GlobalConstants from "../../../../config/GlobalConstants";
import {TigerButton} from "../../../../global/tiger-button/TigerButton";
import {TigerSpinner} from "../../../../global/tiger-spinner/TigerSpinner";
import googleTagManagerWrapper from "../../../../api/GoogleTagManagerWrapper";
import Cookies from 'universal-cookie';
import {GetUnauthorizedResources} from "../../../../api/SpineClient";

export default function BundleColorChoser() {

    const [loading, setLoading] = useState();
    const [selectedColor, setSelectedColor] = useState();
    const [product, setProduct] = useState();
    const [shopifyProduct, setShopifyProduct] = useState();
    const [selectedDisplayColor, setSelectedDisplayColor] = useState();
    const history = useHistory();
    const [coloredBoxImageUrl, setColoredBoxImageUrl] = useState();
    const [planVariantId, setPlanVariantId] = useState();
    const [componentId, setComponentId] = useState();
    const [discountId, setDiscountId] = useState();

    const [hardwareProducts, setHardwareProducts] = useState([]);
    const [selectedGtin, setSelectedGtin] = useState();

    document.title = Strings.PURCHASE_BUNDLE_BROWSER_TAB;

    useEffect(() => {

        setLoading(true);

        const queryParams = new URLSearchParams(window.location.search);
        let planVariantId = queryParams.get("planVariantId");
        setPlanVariantId(planVariantId);
        let componentId = queryParams.get("componentId");
        setComponentId(componentId);
        setDiscountId(queryParams.get("discountId"));

        GetUnauthorizedResources('api/purchases/products/search/by-planvariant-id?planVariantId=' + planVariantId, {}, 'application/json', GlobalConstants.BILL_HOST)
            .then(r => {
                //In case of an error, redirect to the error page
                if (r.error || r.result == null) {
                    googleTagManagerWrapper.event('cancelPurchase', {reason: 'criticalError'});
                    googleTagManagerWrapper.noPurchase();
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=2");
                    return null;
                }

                let product = r.result;

                if (product.type == null) {
                    googleTagManagerWrapper.event('cancelPurchase', {reason: 'criticalError'});
                    googleTagManagerWrapper.noPurchase();
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=3");
                    return null;
                }
                setProduct(product);

                const cookies = new Cookies();
                let consentCookie = cookies.get('borlabs-cookie');
                if (consentCookie?.consents?.statistics?.includes("google-analytics")) {
                    googleTagManagerWrapper.consentGoogleAnalytics();
                }

                //get Billwerk Component
                loadBillwerkComponent(componentId).then(component => {
                    if (component) {
                        //get custom field shopifyProductId from component
                        let shopifyProductId = component.CustomFields.shopifyProductId;

                        if (shopifyProductId) {
                            getShopifyData(shopifyProductId);
                        }
                    }
                });
            });

        //use shopifyProductId to retrieve the variant stock levels of the product
        const getShopifyData = (shopifyProductId) => {
            GetUnauthorizedResources('api/purchases/bundles/stocks?shopifyProductId=' + shopifyProductId)
                .then(async response => {
                    //In case of an error, redirect to the error page
                    if (response.error || response.result == null) {
                        googleTagManagerWrapper.event('cancelPurchase', {reason: 'criticalError'});
                        googleTagManagerWrapper.noPurchase();
                        history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=22");
                        return null;
                    }
                    setShopifyProduct(response.result);
                    let variants = response.result.variants;
                    setHardwareProducts(variants);
                    for (const variant of variants) {
                        //retrieve variant image infos
                        let response = await GetUnauthorizedResources('api/purchases/bundles/search/shopify-image-by-id' +
                            '?shopifyProductId=' + shopifyProductId +
                            '&shopifyImageId=' + variant.image_id);
                        if (!response.error) {
                            variant.productImageUrl = response.result.src;
                        }

                        //retrieve variant metafields
                        let metafieldResponse = await GetUnauthorizedResources('api/purchases/bundles/search/shopify-metafields-by-variant-id' +
                            '?shopifyProductId=' + shopifyProductId +
                            '&shopifyVariantId=' + variant.id);
                        if (!metafieldResponse.error) {
                            let metafields = metafieldResponse.result;
                            for (const metafield of metafields) {
                                if (metafield.key === "boxfarbe") {
                                    variant.colorHexValue = metafield.value;
                                    break;
                                }
                            }
                        }
                    }

                    if (variants.length > 0) {
                        let first = variants[0];
                        setSelectedGtin(first.barcode);
                        setSelectedColor(first.option1);
                        setSelectedDisplayColor(first.title);
                        setColoredBoxImageUrl(first.productImageUrl);
                    }
                    setLoading(false);
                })

        }

        const loadBillwerkComponent = async (componentId) => {
            let response = await GetUnauthorizedResources('api/purchases/billwerk/components/' + componentId)
            if (response.error || response.result == null) {
                googleTagManagerWrapper.event('cancelPurchase', {reason: 'criticalError'});
                googleTagManagerWrapper.noPurchase();
                history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=6");
                return;
            }

            return response.result;
        }

    }, [history]);


    const onChangeStep = (step) => {
        switch (step) {
            case 0:
                //Active step -> Do nothing
                return;
            case 1:
                return;
            case 2:
                return;
            case 3:
                return;
            case 4:
                return;
            default:
                return;
        }
    }

    const getProductVariant = (colorHexValue) => {
        for (const hardwareProduct of hardwareProducts) {
            if (hardwareProduct.colorHexValue === colorHexValue) {
                return hardwareProduct;
            }
        }
        return null;
    }

    const submitBundleColor = (e) => {
        e.preventDefault();
        googleTagManagerWrapper.colorChosed(selectedColor);
        history.push("/" + GlobalConstants.APP_PATH + 'purchase/' + planVariantId + '?selectedColor=' + selectedColor + '&selectedGtin=' + selectedGtin + "&componentId=" + componentId + (discountId ? "&discountId=" + discountId : ''));
    }

    function boxColorChanged(color) {
        let variant = getProductVariant(color);
        if (variant) {
            setSelectedGtin(variant.barcode);
            setSelectedColor(variant.option1);
            setSelectedDisplayColor(variant.title);
            setColoredBoxImageUrl(variant.productImageUrl);
        }
    }

    if (loading) {
        return <TigerSpinner />
    }

    return (

        <div className="color-choser-container purchase-container">
            <PurchaseStatus step={0} maxStep={0} bundled={true}
                            onChangeStep={(step) => onChangeStep(step)}
            />
            <div className="color-choser-form-container">
                <div className="color-choser-info purchase-info" style={{marginBottom: "0", fontWeight: "bold"}}
                     dangerouslySetInnerHTML={{__html: product?.billwerk_name}}/>
                <div className="color-choser-info purchase-info" style={{marginTop: "0"}}
                     dangerouslySetInnerHTML={{__html: Strings.COLOR_CHOSER_INFO}}/>
                <div className="color-choser-box-image">
                    <img src={coloredBoxImageUrl} className={'colored-box-image'} alt={'tigerbox TOUCH'}/>
                </div>
                <div className="color-choser-colors">
                    {hardwareProducts.map(p => (
                        <div role={"button"} onClick={() => boxColorChanged(p.colorHexValue)}
                             key={p.barcode}
                             className={"color-choser-box" + (p.barcode === selectedGtin ? ' selected-color' : '')}
                             style={{backgroundColor: p.colorHexValue}}>&nbsp;</div>
                    ))}
                </div>

                <div className="color-choser-info purchase-info"  style={{width: "100%"}}
                     dangerouslySetInnerHTML={{__html: shopifyProduct?.title + "<br/>" + Strings.COLOR_CHOSER_SELECTED_COLOR.replace('{0}', selectedDisplayColor)}}/>
                <TigerButton className="w-100" variant="red" disabled={!selectedColor}
                             type="button" onClick={(e) => submitBundleColor(e)}>
                    {Strings.PURCHASE_PROCEED_BUTTON}
                </TigerButton>
            </div>

        </div>
    )
}